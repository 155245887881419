  /**************************
  *** Teacher Dash Styles ***
  **************************/

  .teacherDashContainer {
    background-color: #F5F5DC;
    min-height: 90vh;
    display: flex;
  }
  
  .teacherDashHeaderContainer {
    display: flex;
    justify-content: space-between;
  }
  
  .teacherDashContainer .teacherFunctionsContainer {
    display: flex;
    align-items:flex-end;
    padding: 20px 0;
  }

  
  /* Logout btn styles */
  .logoutBtn {
    height: fit-content;
    padding: 8px 12px;
    margin: 0 0 0 30px;
    border-radius: 5px;
    border: 1px solid #292F36;
    background-color: #f9be2c;
    color: #292F36;
    font-size: 15px;
    transition: 0.2s ease-in-out;
  }

  .logoutBtn:hover {
    border: 1px solid #f9be2c;
    background-color: #292F36;
    color: #f9be2c;
  }

  /* Upload Class Styles */
  .uploadContainer {
    display: flex;
    flex-direction: column;
  }

  .uploadClassInput::file-selector-button {
    margin: 0 5px 5px 0;
    padding: 4px 6px;
    background-color: #f9be2c;
    border-radius: 5px;
    border: 1px solid #292F36;
    transition: 0.2s ease-in-out;
  }

  .uploadClassInput::file-selector-button:hover {
    background-color: #292F36;
    color: #f9be2c;
    border-color: #f9be2c;
  }

  .uploadBtn {
    border-radius: 10px;
    border: 1px solid #292F36;
    background-color: #f9be2c;
    color: #292F36;
    font-size: 15px;
    transition: 0.2s ease-in-out;
  }

  .uploadBtn:hover {
    border: 1px solid #f9be2c;
    background-color: #292F36;
    color: #f9be2c;
  }

  /* Teacher Heading Styles*/
  
  .teacherWelcomeHeading {
    margin: 0;
    padding: 20px 0;
    font-size: 30px;
  }
  
  .classDataContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    width: 80%;
  }
  
  /* Doughnut Chart */
  
  .classDoughnutChart {
    margin-bottom: 20px;
    padding: 25px;
    background-color: #eee;
    border-radius: 10px;
    border: 2px solid black;
    width: 30%;
    min-width: 20%;
    height: max-content;
  }
  
  .doughnutHeader {
    margin: 0;
    padding-bottom: 20px;
  }
  
  /* Line chart */
  
  .classLineChart {
    width: 60%;
    min-width: 30%;
    height: 100%;
    border-radius: 10px;
    display: flex;
    padding: 20px;
    margin-bottom: 20px;
    background-color: #eee;
    border: 2px solid black;
    flex-direction: column;
  }
  
  
  /***************************
  *** Student Popup Styles ***
  ***************************/

  .studentPopupContainer {
    position: fixed;
    z-index: 3;
    top: 10%;
    left: 9%;
    background: white;
    height: fit-content;
    width: 80%;
    border: 2px solid black;
    padding: 20px;
    overflow-y: scroll;
    scrollbar-width: 1px;
    filter: drop-shadow(0 0 50px #000);
  }

  .studentPopupContainer .close {
    float: right;
    font-size: 30px;
    margin: 0 10px 0 0;
  }

  .studentPopupContainer .close:hover {
    cursor: pointer;
  }

  .studentPopupContent {
    display: flex;
    justify-content:flex-start;
    align-items:baseline;
    padding: 10px 0 0 0;
  }

  .studentPopupContent h2 {
    margin: 0 10px 0 0;
  }

  .studentPopupContent p {
    color: #36454F;
    margin: 0 10px 0 0;
  }

  /* Pop up buttons, text, data */

  .weekContentContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }

  .weekButtonContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    padding: 20px 0 0 0;
    height: 40%;
    transition: 0.25s;
  }

  .weekButton {
    background-color: #bbd5ed;
    color: black;
    border-radius: 5px;
    padding: 8px 12px;
    margin: 0 5px 0 5px;
    cursor: pointer;
    box-shadow: inset 0 0 0 0 #bbd5ed;
    -webkit-transition: ease-out 0.4s;
    -moz-transition: ease-out 0.4s;
    transition: ease-out 0.4s;
  }

  .weekButton:hover {
    background-color: white;
  }

  .weekButton:active {
    background-color: white;
  }

  .dataContainer {

  }

  .lessonDetailsDisplay {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    margin: 20px 0 0 0;
  }

  .dataContainer .dataInnerContainer {
    display: flex;
    flex-direction: column;
    margin: 0 0 10px 0;
    border: 1px solid black;
    border-radius: 10px;
    padding: 5px;
  }

  .dataInnerContainer p {
    font-size: 20px;
    color: #353839;
  }

  .generatedStory {
    grid-area: 1 / 1 / 3 / 2;
  }

  .transcript {
    grid-area: 1 / 2 / 3 / 3;
  }

  .score {
    grid-area: 1 / 3 / 2 / 4;
  }

  .audio {
    grid-area: 2 / 3 / 3 / 4;
  }

  .dataInnerContainer h3 {
    margin: 0;
    padding: 0 10px 0 0;
  }

  .dataInnerContainer p {
    margin: 0;
  }

  .lessonDisplayBottomBtns{
    display: flex;
    justify-content: space-between;
  }

  .passFailBtns {
    grid-area: 3 / 1 / 3 / 3;
  }

  .passFailBtns button {
    padding: 8px 12px;
    margin: 0 8px 0 0;
    border-radius: 8px;
    color: white;
  }

  .passFailBtns button:hover {
    opacity: 0.7;
    cursor: pointer;
  }

  .passFailBtns button:nth-child(odd) {
    background-color: green;
  }

  .passFailBtns button:nth-child(even) {
    background-color: red;
  }

  .prevNextBtns {
    grid-area: 3 / 3 / 3 / 4;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .prevNextBtns p {
    margin: 0;
  }

  .prevNextBtns button {
    padding: 8px 12px;
    border-radius: 4px;
    margin: 0 0 0 8px;
    background-color: lightsteelblue;
  }

  .prevNextBtns button:hover {
    background-color: white;
    cursor: pointer;
  }

  .prevNextBtns button p {
    margin: 0;
  }

  .prevNextBtns button:nth-child(odd) {
    transform: scaleX(-1);
  }

  

  .clickable-word {
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.clickable-word:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.clickable-word.errored {
    color: red;
    font-weight: bold;
}
