/********************************
***.jsx components stylesheet ***
********************************/

/********************
*** Mobile Styles ***
********************/


/* Student Dash Mobile Styles */
.nextLessonContainer {
  display: flex;
  border-top: 3px solid black;
  border-bottom: 3px solid black;
  justify-content: space-around;
  align-items: center;
}

.nextLessonContainer button {
  height: fit-content;
  padding: 8px 16px;
  border: none;
  background-color: #f9be2c;
  border-radius: 5px;
}

/********************
*** Tablet Styles ***
********************/

@media screen and (min-width: 768px) and (max-width: 1023px) {
  
}

/********************* 
*** Desktop Styles ***
*********************/
@media screen and (min-width: 1024px) {

/**************************** 
*** Demo Component Styles ***
****************************/

/* Demo Component */

.demoContainer {
  padding: 50px 0;
  background-color: #F5F5DC;
}

.demoContainer h2 {
  color: #292F36;
  margin: 0px;
  padding: 0px;
  font-size: 40px;
  text-decoration: underline wavy #292F36;
  -webkit-text-decoration: underline wavy #292F36;
}

/* Week input styles */

.weekInput {
  background-color: #F5F5DC;
  font-size: 25px;
  border: 2px solid #292F36;
  padding: 12px 8px;
  border-radius: 10px;
}

.weekInput option {
  color: #292F36;
  padding: 3px 0;
}

/* Select headers */

.selectHeader {
  font-size: 25px;
  color: #292F36;
}

/* Select Character styles */

.characterSelect {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 0;
}

.characterBtn {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 30px;
  padding: 25px 25px;
  border: none;
  border-radius: 10px;
  background: #292f36;
  color: white;
  border-radius: 16px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  transition: all .2s ease-in-out;
  min-width: 200px;
  max-width: 250px;
  flex: 1 0 21%;
}

.characterBtn:hover{
  transform: scale(1.1);
  cursor: pointer;
}

.characterBtn div {
  
}

.characterBtn p {
  color: #292F36;
}

.characterBtn .buttonIcon {
  color: rgb(243, 192, 59);
  height: 75px;
  pointer-events: none;
  padding: 50px 0px;
}

.characterBtn div {
  background-color: rgb(39, 111, 191);
  width: 100%;
  padding: 20px 0px;
  border-radius: 0px 0px 13px 13px;
}

/* Select Setting Styles */

.settingSelect {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px 0;
}

.settingBtn {
  display: flex;
  padding: 0;
  margin: 10px 30px;
  flex-direction: column;
  align-items: center;
  font-size: 30px;
  border: none;
  border-radius: 20px;
  background-color: rgb(247, 247, 247);
  color: white;
  border-radius: 16px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  transition: all .2s ease-in-out;
  min-width: 200px;
  max-width: 250px;
  flex: 1 0 21%;
  border: 2px solid #AA4747;
}

.settingBtn div {
  background-color: #AA4747;
  width: 100%;
  padding: 20px 0px;
  border-radius: 0px 0px 13px 13px;
}

.settingBtn:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.settingBtn p {
  color: #292F36;
}

.settingBtn .buttonIcon {
  color: rgb(243, 192, 59);
  height: 75px;
  pointer-events: none;
  padding: 50px 0px;
}

/* Loading icon animation */

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #292F36;
  color: #292F36;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

/* Generated Content Styles

.generatedContentSection {
  display: flex;
  flex-direction: row;
  border-radius: 20px;
  background-color: #FFF;
  width: 90%;
  border: 2px solid black;
}

.generatedImgArea {
  border-right: 2px solid black;
  padding: 20px;
}

.generatedImgArea img {
  border-radius: 20px 0 0 20px;
  border: 2px solid black;
  height: 600px;
}

.generatedStoryArea {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.audio-recorder {
  display: none!important;
}

.generatedStoryArea p {
  font-size: 40px;
}

.generatedStoryArea div {
  display: flex;
  width: 60%;
  justify-content: space-evenly;
}

.generatedStoryArea .arrowBtn {
  background-color: initial;
  border: 1px solid #f9be2c;
  border-radius: 30px;
}

.generatedStoryArea .arrowBtn:hover {
  background-color: #FDEBBF;
  border: 1px solid #f9be2c;
  border-radius: 30px;
}

.generatedStoryArea .arrowBtn:active {
  background-color: #f9be2c;
  border: 0.5px solid #f9be2c;
  border-radius: 30px;
}

.generatedStoryArea .arrowBtn:disabled .storyArrows {
  color: #c99a24;
  height: 50px;
  width: 40px;
}

.generatedStoryArea .storyArrows {
  color: #f9be2c;
  height: 50px;
  width: 40px;
}

.recordingIndicator {
  top: 10px;
  position: absolute;
  display: flex;
  align-items: center;
}

.recordingIndicator strong {
  margin-left: 5px; 
  font-weight: bold;
}

*/
  /****************************
  *** Student SignIn Styles ***
  ****************************/
  
  
  

  


  /*******************************
  *** Student Dashboard Styles ***
  *******************************/

  /* Welcome Banner Styles */
  .welcomeBannerContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    background-repeat: repeat-x;
    background-size:contain;
    height: 400px;
  }
    
  .wb-left { grid-area: 1 / 1 / 2 / 2; }
  .wb-right { 
    grid-area: 1 / 2 / 2 / 3; 
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
  }

  .wb-right img {
    height: 200px;
  }

  .thoughtBubbleOne {
    position: absolute;
    top: 220px;
    left: 325px;
    background-color: white;
    border: #000;
    border-radius: 50%;
    height: 10px;
    width: 10px;
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  .thoughtBubbleThree {
    position: absolute;
    top: 150px;
    left: 200px;
    background-color: white;
    border: #000;
    border-radius: 50%;
    height: 75px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  @keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
  }

  /* Student Statistics Dashboard */

  .studentLessonsBody {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    width: 100%;
  }

  .lessonsContainer {

  }
  .lessonCard {
    display: flex;
    grid-area: 1 / 1 / 2 / 2;
    flex-direction: column;
    background-color: rgb(238, 238, 238, 0.9);
    border: 2px solid black;
    padding: 10px 20px;
    border-radius: 10px;
    height: -moz-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    align-items: normal;
  }

  .lessonCard div {
    display: flex;
    flex-direction: column;
  }

  .lessonCard div p button {
    height: fit-content;
    width: 20%;
    padding: 8px 16px;
    border: none;
    background-color: #f9be2c;
    border-radius: 5px;
    font-size: 20px;
    float: right;
    transition: 0.2s ease-in-out;
  }

  .lessonCard div p button:hover {
    cursor: pointer;
    background-color: #fbd26b;
  }

  .lessonCard p {
    font-size: 30px;
    padding: 0;
    margin: 0;
  }

  .lessonCard h3 {
    font-size: 30px;
    padding: 0;
    margin: 0;
  }

  .lessonCard div .previousScore {
    font-size: 50px;
    text-decoration: none;
  }

  .studentStats {
    display: flex;
    grid-area: 2 / 1 / 3 / 2;
    flex-wrap: wrap;
    background-color: rgb(238, 238, 238, 0.9);
    border: 2px solid black;
    border-radius: 10px;
    padding: 20px;
    max-height: 200px;
  }

  .studentStats audio {
    align-self: baseline;
    padding-top: 50px;
  }

  .studentStats audio::-webkit-media-controls-panel {
    background-color: transparent;
  }

  .studentStats p {
    text-decoration: underline;
    font-size: 20px;
    padding: 0;
    margin: 0;
  }

  .studentStats h3 {
    font-size: 30px;
    margin: 0;
  }

  .studentStats div {
    display: flex;
    overflow: hidden;
    max-height: 200px;
  }

  /****************************
  *** Lesson Session Styles ***
  ****************************/

  .studentLessonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 80vh;
    justify-content: center;
    padding: 20px 0 20px 0;
  }

  .startCreatingBtn button {
    background-color: #f9be2c;
    border: none;
    border-radius: 5px;
    padding: 8px 12px;
    font-size: 30px;
  }

  .lessonSelectCharacter {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .lessonSelectCharacter .characterBtn {
    margin: 10px 30px;
    padding: 0px;
    background-color: rgb(247, 247, 247);
    border: 2px solid rgb(39, 111, 191);
  }

  .lessonSelectCharacter .lessonSelectHeader {
    font-size: 50px;
    margin: 0 0 20px 0;
  }

  .lessonSelectHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .lessonSelectHeader .lessonSelectHeader {
    font-size: 50px;
    margin: 0 0 20px 0;
  }

}
