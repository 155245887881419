.headerAndButtons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
}

.headerAndButtons h2 {
    margin: 0;
}

.headerButtons {
    color: #5A5A5A;
    background-color: transparent;
    border: none;
    font-size: 15px;
    padding-left: 30px;
}

.headerButtons:hover {
    transform: scale(1.1);
    cursor: pointer;
    transition: ease-in-out 0.1s;
}

.headerButtons .buttonIcon {
    margin-right: 5px;
}

.roster {
    list-style: none;
    padding: 0;
    border-radius: 8px;
}

.individualStudent:first-child{
    border-radius: 8px 8px 0 0;
}

.individualStudent:last-child {
    border-radius: 0 0 8px 8px;
}

.individualStudent {
    border: 1px solid black;
    background-color: white;
    padding: 20px;
    font-size: 20px;
}

.individualStudent p {
    margin: 0;
}

.individualStudent .notEditing {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.individualStudent .editing {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.editing label:nth-child(3) {
    margin-left: 10px;
}

.editing label:nth-child(5) {
    margin-left: 10px;
}

.editing input {
    font-size: 20px;
    border: none;
    border-bottom: 1px solid black;
    margin-left: 10px;
}

.editing input:focus-visible {
    outline: none;
}

.editConfirmBtn {
    font-size: 20px;
    margin-right: 15px;
    background-color: #6DA34D;
    color: white;
    border: 1px solid #6DA34D;
    border-radius: 4px;
    padding: 4px 12px;
}

.editConfirmBtn:hover {
    background-color: white;
    color: #6DA34D;
    transition: ease-in-out 0.1s;
    cursor: pointer;
}

.editCancelBtn {
    font-size: 20px;
    margin-right: 15px;
    background-color: #AA4747;
    color: white;
    border: 1px solid #AA4747;
    border-radius: 4px;
    padding: 4px 12px;
}

.editCancelBtn:hover {
    background-color: white;
    color: #AA4747;
    transition: ease-in-out 0.1s;
    cursor: pointer;
}

.studentBtn {
    background-color: transparent;
    border: none;
    margin: 0 10px;
}

.studentBtn:hover {
    cursor: pointer;
    transform: scale(1.1);
    transition: ease-in-out 0.1s;
}

.studentBtnIcon {
    height: 20px;
}


.addStudentForm {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
}

.signInAddStudentForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.signInAddStudentForm div {
    display: flex;
    margin-top: 20px;
}

.addStudentForm div {
    display: flex;
}

.studentSignInAddStudentInput {
    font-size: 20px;
    margin: 0 5px 20px 5px;
}

.newStudentInput {
    border: none;
    border-bottom: 1px solid black;
    background-color: transparent;
    margin-right: 10px;
    font-size: 20px;
}

.newStudentInput:focus-visible {
    outline: none;
}

.signInConfirmAddStudentBtn {
    padding: 8px 16px;
    font-size: 20px;
    border-radius: 4px;
    background-color: #44AF69;
    color: white;
    border: 1px solid #44AF69;
    transition: ease-in-out 0.2s;
}

.signInConfirmAddStudentBtn:hover {
    background-color: #fff;
    color: #44AF69;
    border-radius: 4px;
    cursor: pointer;
}

.confirmAddStudentBtn {
    font-size: 20px;
    border-radius: 4px;
    background-color: #44AF69;
    color: white;
    border: 1px solid #44AF69;
    padding: 2px 12px;
}

.confirmAddStudentBtn:hover {
    background-color: #fff;
    color: #44AF69;
    border-radius: 4px;
    transition: ease-in-out 0.1s;
    cursor: pointer;
}


