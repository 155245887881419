* {
    font-family: "Quicksand", sans-serif;
    font-style: normal;
}

@media screen and (max-width: 450px) {
    .featuresContainer {
        display: block;
    }

    .feature {
        padding: 10% 5%;
    }

    .feature .featureIcon {
        height: 60px;
    }

    .feature h3 {
        font-size: 20px;
    }

    .feature p {
        font-size: 16px;
    }

    .buildClass {
        background-color: #276FBF;
    }

    .createStory {
        background-color: #AA4747;
    }

    .assessStudent {
        background-color: #F3C03B;
    }
}

@media screen and (min-width: 451px) and (max-width: 1023px) {
    .featuresContainer {
        display: block;
    }

    .feature {
        display: flex;
        flex-direction: column;
        padding: 3% 5%;
    }

    .feature .featureIcon {
        height: 100px;
    }

    .feature h3 {
        font-size: 40px;
    }

    .feature p {
        font-size: 30px;
    }

    .buildClass {
        background-color: #276FBF;
    }

    .createStory {
        background-color: #AA4747;
    }

    .assessStudent {
        background-color: #F3C03B;
    }
}

@media screen and (min-width: 1024px) {
    .featuresContainer {
        display: flex;
    }

    .feature {
        display: flex;
        flex-direction: column;
        padding: 3% 5%;
        width: 33.3%;
    }

    .feature .featureIcon {
        height: 50px;
    }

    .feature h3 {
        font-size: 30px;
    }

    .feature p {
        font-size: 20px;
    }

    .buildClass {
        background-color: #276FBF;
    }

    .createStory {
        background-color: #AA4747;
    }

    .assessStudent {
        background-color: #F3C03B;
    }
}