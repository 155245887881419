@media screen and (max-width: 450px) {
    .startTodayContainer {
        background-color: #276FBF;
        padding: 30px 2%;
    }

    .startTodayContainer h2 {
        font-size: 20px;
    }

    .startTodayContainer p {
        font-size: 16px;
        margin-bottom: 30px;
    }

    .startTodayContainer p a {
        color: #000;
    }

    .ctaButton {
        text-decoration: none;
        background-color: #276FBF;
        color: black;
        border: 1px solid black;
        padding: 8px 16px;
        border-radius: 4px;
        font-size: 16px;
    }
}

@media screen and (min-width: 451px) and (max-width: 1023px) {
    .startTodayContainer {
        background-color: #276FBF;
        margin: 50px 0 0 0;
        padding: 50px 10%;
    }

    .startTodayContainer h2 {
        font-size: 40px;
        font-weight: bolder;
        margin: 0 0 10px 0;
    }

    .startTodayContainer p {
        font-size: 25px;
        margin-bottom: 40px;
    }

    .startTodayContainer p a {
        color: #000;
    }

    .ctaButton {
        text-decoration: none;
        background-color: #276FBF;
        color: black;
        border: 1px solid black;
        padding: 8px 16px;
        border-radius: 4px;
        font-size: 20px;
        box-shadow: -0.5px 2px 2px 1px;
    }

    .ctaButton:active {
        box-shadow: inset 3px 3px 1px;
    }
}

@media screen and (min-width: 1024px) {
    .startTodayContainer {
        background-color: #276FBF;
        padding: 55px 10%;
    }

    .startTodayContainer h2 {
        font-size: 30px;
        font-weight: bolder;
        margin: 0 0 10px 0;
    }

    .startTodayContainer p {
        font-size: 20px;
        margin-bottom: 40px;
    }

    .startTodayContainer p a {
        color: rgb(255, 255, 255, 0.7);
    }

    .ctaButton {
        text-decoration: none;
        background-color: #276FBF;
        color: black;
        border: 1px solid black;
        padding: 8px 16px;
        border-radius: 4px;
        font-size: 20px;
        margin: 20px 0 0 0;
    }

    .ctaButton:hover {
        background-color: rgba(255, 255, 255, 0.2);
        border: 1px solid #000;
        color: #000;
        transition: ease-in-out 0.3s;
    }
}