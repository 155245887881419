.classStatsContainer {
    display: flex;
    flex-direction: row;
    width: 90%;
    margin-bottom: 10px;
    margin-top: 10px;
    height: fit-content;
}

.unitChart {
    background-color: #fff;
    border: 2px solid black;
    border-radius: 8px;
    width: 30%;
    margin-right: 10px;
    text-align: center;
}

.horizontalCards {
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: #fff;
    border: 2px solid black;
    border-radius: 8px;
    text-align: center;
}