@media screen and (max-width: 450px) {
    .goalContainer {
        padding: 0 5%;
        margin: 25px 0;
    }

    .goalContainer h2 {
        font-size: 20px;
    }

    .goalContainer p {
        font-size: 16px;
    }
}

@media screen and (min-width: 451px) and (max-width: 1023px) {
    .goalContainer {
        display: flex;
        flex-direction: column;
        padding: 0 10%;
    }

    .goalContainer h2 {
        font-weight: bolder;
        font-size: 40px;
        margin-bottom: 10px;
    }

    .goalContainer p {
        font-size: 25px;
        margin: 0 0 40px 0;
    }
}

@media screen and (min-width: 1024px) {
    .goalContainer {
        display: flex;
        flex-direction: column;
        padding: 25px 10%;
        background-color: rgb(247, 246, 240);
    }

    .goalContainer h2 {
        font-weight: bolder;
        font-size: 30px;
        margin-bottom: 10px;
    }

    .goalContainer p {
        font-size: 20px;
        margin: 0 0 40px 0;
    }
}