/************************************
*** Student Dash Component Styles ***
************************************/

.progressContainer {
    background-color: '#e0e0de';
    border-radius: '50px';
}

.progressFiller {
    height: 100%;
    width: 0%;
    background-color: '#4caf50';
    border-radius: inherit;
    text-align: right;
    transition: width 0.5s ease-in-out;
}

/**********************
*** Student Sign In ***
**********************/

@media screen and (max-width: 450px) {
  .signInPage {
    background-color: beige;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 80vh;
    text-align: -webkit-center;
  }
  
  
  .studentSigninContainer {
      display: flex;
      flex-direction: column;
      background-color: white;
      border-radius: 30px;
      height: -moz-fit-content;
      height: fit-content;
      padding: 50px 0px;
      border: solid 1px #292F36;
    }
  
    .studentSignInHeader {
      color: #292F36;
      font-size: 24px;
      font-weight: bold;
      padding-bottom: 0;
      margin-bottom: 50px;
    }
  
    .studentSigninContainer .classCode {
      height: 20px;
      width: 70%;
      place-self: center;
      border: none;
      border-bottom: 1px solid #000;
      border-radius: 0px;
      font-size: 16px;
    }
  
    .submitClassCodeBtn {
      border: none;
      background-color: #276FBF;
      color: white;
      font-size: 20px;
      padding: 8px 12px;
      width: 35%;
      border-radius: 8px;
      margin: 20px 0 10px 0;
      align-self: center;
    }
  
    .submitClassCodeBtn:hover {
      border: inset 1px #276FBF;
      background-color: transparent;
      color: #000;
      cursor: pointer;
  }

    .studentSigninContainer p {
      font-size: 12px;
    }
  
  
    .studentListContainer {
      display: flex;
      flex-direction: column;
      background-color: white;
      margin-bottom: 20px;
      border-radius: 8px 8px 0 0;
      border: 1px solid black;
    }
    
    .studentListHeader {
      font-size: 30px;
      padding: 20px 0 20px 0;
      font-weight: bold;
      text-align: center;
      position: sticky;
      background-color: #276FBF;
      border-radius: 8px 8px 0 0;
      color: white;
    }
    
    .studentList {
      list-style-type: none;
      padding: 0;
      font-size: 20px;
      max-height: 300px;
      overflow-x: hidden;
      overflow-y: scroll;
      margin: 0;
    }
    
    .studentList li {
      padding: 10px 0;
      border: 1px solid #000;
      margin: 0 0 5px 0;
    }
  
    .studentList li:hover {
      cursor: pointer;
    }
    
    .studentList li:last-child {
      margin: 0 0 0 0;
    }
  
    .backButton {
      font-size: 20px;
      padding: 8px 16px;
      background-color: #276FBF;
      color: white;
      border: 1px solid black;
      border-radius: 4px;
    }
  
    .backButton:hover {
      background-color: white;
      color: #276FBF;
      border: 1px solid #276FBF;
      transition: ease-in-out 0.3s;
      cursor: pointer;
    }
}

@media screen and (min-width: 451px) {
  .signInPage {
    background-color: beige;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 90vh;
    text-align: -webkit-center;
  }
  
  
  .studentSigninContainer {
      display: flex;
      flex-direction: column;
      background-color: white;
      border-radius: 30px;
      height: -moz-fit-content;
      height: fit-content;
      padding: 100px 50px;
      border: solid 1px #292F36;
      width: 50%;
    }
  
    .studentSignInHeader {
      color: #292F36;
      font-size: 30px;
      font-weight: bold;
      padding-bottom: 0;
      margin-bottom: 50px;
    }
  
    .studentSigninContainer .classCode {
      height: 30px;
      width: 70%;
      place-self: center;
      border: none;
      border-bottom: 1px solid #000;
      border-radius: 0px;
      font-size: large;
    }
  
    .submitClassCodeBtn {
      border: inset 1px #276FBF;
      background-color: #276FBF;
      color: white;
      font-size: 20px;
      padding: 8px 12px;
      width: 50%;
      border-radius: 8px;
      margin: 30px 0 20px 0;
      align-self: center;
    }
  
    .submitClassCodeBtn:hover {
      border: inset 1px #276FBF;
      background-color: transparent;
      color: #000;
      cursor: pointer;
  }
  
  
    .studentListContainer {
      display: flex;
      flex-direction: column;
      background-color: white;
      margin-bottom: 20px;
      border-radius: 8px 8px 0 0;
      border: 1px solid black;
    }
    
    .studentListHeader {
      font-size: 30px;
      padding: 20px 0 20px 0;
      font-weight: bold;
      text-align: center;
      position: sticky;
      background-color: #276FBF;
      border-radius: 8px 8px 0 0;
      color: white;
    }
    
    .studentList {
      list-style-type: none;
      padding: 0;
      font-size: 20px;
      max-height: 300px;
      overflow-x: hidden;
      overflow-y: scroll;
      margin: 0;
    }
    
    .studentList li {
      padding: 10px 0;
      border: 1px solid #000;
      margin: 0 0 5px 0;
    }
  
    .studentList li:hover {
      cursor: pointer;
    }
    
    .studentList li:last-child {
      margin: 0 0 0 0;
    }
  
    .backButton {
      font-size: 20px;
      padding: 8px 16px;
      background-color: #276FBF;
      color: white;
      border: 1px solid black;
      border-radius: 4px;
      transition: ease-in-out 0.2s;
    }
  
    .backButton:hover {
      background-color: white;
      color: #276FBF;
      border: 1px solid #276FBF;
      cursor: pointer;
    }

    .addStudentBtn {
      font-size: 20px;
      padding: 8px 16px;
      background-color: #44AF69;
      color: white;
      border: 1px solid black;
      border-radius: 4px;
      transition: ease-in-out 0.2s;
      margin-left: 10px;
    }

    .addStudentBtn:hover {
      background-color: white;
      color: #44AF69;
      border: 1px solid #44AF69;
      cursor: pointer;
    }
}
