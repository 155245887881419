.teacherStoryGenerationContainer {
    background-color: white;
    width: 80%;
    margin: 30px auto;
    border: 1.5px solid #000;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}

.structureContainer .topicAndSetting {
    display: flex;
    justify-content: space-evenly;
}

.floating
{
  position: relative;
  margin-top: 1.75em;
}

.floating input[type="text"]
{
  border: none;
  border-bottom: 1px solid gray;
  margin-bottom: 1px;
}

.floating input[type="text"]:hover
{
  border-bottom: 2px solid black;
  margin-bottom: 0;
}

.floating input[type="text"]:focus,
.floating input[type="text"]:active
{
  outline: none;
  border-bottom: 2px solid #2196f3;
  margin-bottom: 0;
}

.floating input[type="text"] + label
{
  position: absolute;
  pointer-events: none;
  bottom: 0.1em;
  left: 0.1em;
  color: gray;
  font-size: 1.0em;
  transition: margin 0.2s ease,
              color 0.2s ease,
              font-size 0.2s ease;
}

.floating input[type="text"]:focus + label,
.floating input[type="text"]:active + label,
.floating input[type="text"]:valid + label
{
  pointer-events: auto;
  margin-bottom: 1.75em;
  font-size: 0.7em;
}

.floating input[type="text"]:focus + label,
.floating input[type="text"]:active + label
{
  color: #2196f3;
}

.floating input[type="text"].empty:not(:focus) + label,
.floating input[type="text"].empty:not(:active) + label
{
  pointer-events: none;
  margin-bottom: 0;
  color: gray;
  font-size: 1.0em;
}

.storyLength {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-top: 20px;
}

.storyLength select {
  margin-top: 5px;
}

.soundDropdownsContainer {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  margin: 20px 0;
}

.soundDropdowns {
  flex: 1 0 30%;
}

.soundDropdowns select {
  padding: 2px;
  border: none;
  border-bottom: 1px solid black;
  margin-bottom: 5px;
}

.selectedStructures {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.selectedStructure {
  background-color: #eee;
  width: fit-content;
  flex: 1 0 21%;
  margin: 3px 4px;
  padding: 2px;
  border-radius: 4px;
}

.selectedStructure:hover {
  cursor: no-drop;
}

.teacherStoryGenerateBtn {
  background-color: #4CAF50;
  color: #fff;
  border: 1px solid #4CAF50;
  padding: 8px 12px;
  border-radius: 4px;
  margin: 20px 0 0 0;
}

.teacherStoryGenerateBtn:hover {
  border: 1px solid #4CAF50;
  color: #4CAF50;
  background-color: white;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}