.settingsPage {
    width: 80%;
    margin: 0px auto;
}

.settingsPage hr {
    border-top: 1px solid black;
}

.settingsContainer {
    background-color: white;
    border: 2px solid black;
    border-radius: 8px;
    padding: 8px 12px;
    display: flex;
    flex-direction: column;
}

.pageHeading {
    margin-top: 50px;
}

/*********************
*** Library Styles ***
*********************/

.libraryHeaderButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.libraryCreateStory {
    padding: 8px 12px;
    background-color: #276FBF;
    border: 1px solid #276FBF;
    border-radius: 8px;
    margin-right: 20px;
    color: white;
}

.libraryCreateStory:hover {
    border: 1px solid #276FBF;
    color: #276FBF;
    background-color: white;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    z-index: 3;
}