.teacherDashSidebar {
    display: flex;
    flex-direction: column;
    z-index: 2;
    background-color: white;
    overflow-x: hidden;
    transition: 0.5s;
    border-right: solid 2px black;
    padding: 20px 0 0 0;
    justify-content: space-between;
}

.teacherDashSidebar .profile {
    display: grid;
    grid-template-columns: repeat(2, 1fr), 0.5fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    font-size: 15px;
}

.profileDiv1 { 
    grid-area: 1 / 1 / 2 / 2;
    align-content: center;
    justify-self: center;
    position: relative;
}
.profileDiv1 div {
    background-color: #5A5A5A;
    opacity: 0;
    position: absolute;
    top: 2px;
    left: 2px;
    height: 50px;
    width: 50px;
    border-radius: 50%;
}

.profileDiv1:hover {
    cursor: pointer;
}

.profileDiv1:hover div {
    opacity: 0.2;
}

.profileDiv2 { 
    grid-area: 1 / 2 / 2 / 3; 
    align-content: center;
}
.profileDiv3 { 
    grid-area: 1 / 3 / 2 / 4; 
    align-content: center;
}

.notificationBell {
    height: 25px;
    color: #5A5A5A;
}

.profile p {
    margin: 0 0 2px 0;
}

.profile img {
    height: 50px;
    border: 2px solid black;
    border-radius: 50%;
}

.links {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 20px auto;
}

.linkHeading {
    margin: 0;
    color: black;
}

.linkWithIcon {
    text-decoration: none;
    color: black;
}

.linkHeading .linkHeadingIcon {
    margin-right: 10px;
    height: 20px;
    width: 20px;
}


.links hr {
    height: 2px;
    color: black;
    background-color: black;
    width: 100%;
    margin: 10px 0 10px 0;
}

.subLink {
    text-decoration: none;
    margin-left: 30px;
    color: #5A5A5A;
    text-decoration: none;
    background-image: linear-gradient(#5A5A5A, #5A5A5A);
    background-size: 0% 0.1em;
    background-position-y: 100%;
    background-position-x: 0%;
    background-repeat: no-repeat;
    transition: background-size 0.2s ease-in-out;
}

.subLink:hover {
    background-size: 100% 0.1em;
}

.teacherDashSidebar .bottomLinks {
    margin: 0 auto 10px auto;
    width: 80%;
}

.bottomLinkIcon {
    margin: 0 10px 0 0;
}

.bottomLinks p {
    margin: 10px 0;
    padding: 4px;
    border-radius: 4px;
}

.bottomLinks p:hover {
    cursor: pointer;
    background-color:rgb(211,211,211, 0.1);
    transition: ease-in-out 0.2s;
}

.sidebarBtn {
    border-right: solid 2px black;
    border-bottom: solid 2px black;
    border-left: none;
    border-radius: 0 0 8px 0;
    background-color: #276FBF;
}


.sidebarBtn:hover {
    background-color: #276FBF;
    opacity: 1.1;
    cursor: pointer;
}

.sidebarBtnIcon {
    height: 30px;
}


