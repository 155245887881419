@media screen and (max-width: 450px) {
    .newDemo {
        background-color: rgb(247, 246, 240);
        display: flex;
        flex-direction: column;
        padding: 30px 0;
        align-items: center;
    }

    .newDemo .lds-ellipsis {
        place-self: center;
    }
    
    .demoStructure {
        text-align: left;
        width: 80%;
    }

    .decodableGenerator {
        font-size: 20px;
    }

    .demoStructure p {
        font-size: 16px;
        margin-bottom: 5px;
    }
    
    .demoInput {
        font-size: 16px;
        width: 100%;
        margin-bottom: 30px;
        padding: 5px 2px;
        color: black;
    }

    .demoInput option {
        font-size: 15px;
    }
    
    #buttonContainer {
        display: flex;
        justify-content: right;
    }
    
    .demoButton {
        font-size: 16px;
        padding: 8px 12px;
        background-color: #F3C03B;
        border: none;
        border-radius: 4px;
        color: black;
    }

    .clearButton {
        background-color: #AA4747;
        padding: 8px 12px;
        border: none;
        border-radius: 4px;
        margin-left: 10px;
        font-size: 16px;
        margin-bottom: 20px;
    }
    
    .clearButton {
        font-size: 16px;
        padding: 8px 12px;
        background-color: #AA4747;
        border: none;
        border-radius: 4px;
        color: black;
        margin-left: 10px;
    }
    
    /*Generated Content Section*/

    .demoContent {
        background-color: rgb(243, 192, 59, 0.1);
        color: #F3C03B;
        border: 1px solid;
        text-align: -webkit-center;
        width: 80%;
        margin-top: 30px;
    }

    .demoStoryArea {
        width: 90%;
        font-weight: bold;
        font-size: 20px;
    }

    .demoStoryArea div {
        display: flex;
        justify-content: space-evenly;
    }

    .demoStoryArea button {
        border-radius: 50%;
        padding: 10px;
        background-color: #F3C03B;
        margin: 0 0 20px 0;
        border: none;
        color: black;
    }

    .demoArrows {
        height: 25px;
        color: black;
    }

    .demoStoryArea button svg {
        height: 18px;
    }
}


@media screen and (min-device-width: 451px) and (max-device-width: 1023px) {
    .newDemo {
        background-color: white;
        width: 80%;
        margin: 50px auto;
        border: 3px solid #000;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
    }

    .newDemo .lds-ellipsis {
        place-self: center;
    }
    
    .demoStructure {
        text-align: left;
        margin: 20px 10%;
    }
    
    .demoInput {
        font-size: 25px;
        width: 100%;
        margin-bottom: 30px;
        background-color: #eee;
        box-shadow: inset 0px 1px 4px;
    }
    
    .demoStructure p {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 5px;
    }
    
    #buttonContainer {
        display: flex;
        justify-content: center;
    }
    
    .demoButton {
        font-size: 20px;
        padding: 8px 12px;
        background-color: #F3C03B;
        border: none;
        border-radius: 4px;
        box-shadow: 0px 1px 1px 1px;
    }

    .clearButton {
        background-color: #AA4747;
        padding: 8px 12px;
        border: none;
        border-radius: 4px;
        margin-left: 10px;
        font-size: 20px;
        margin-bottom: 20px;
    }
    
    .demoButton:hover {
        box-shadow: 0px 1px 1px 2px;
        transition: ease-in-out 0.3s;
    }
    
    .demoButton:active {
        box-shadow: inset 0px 1px 1px 1px;
    }
    
        /*Generated Content Section*/

        .demoContent {
            border-top: 2px solid black;
            text-align: -webkit-center;
        }
    
        .demoStoryArea {
            font-size: 40px;
            width: 90%;
        }
    
        .demoStoryArea div {
            display: flex;
            justify-content: space-evenly;
        }
    
        .demoStoryArea button {
            border-radius: 50%;
            padding: 10px;
            background-color: #F3C03B;
            margin: 0 0 30px 0;
        }
    
        .demoArrows {
            height: 30px;
        }
}

@media screen and (min-device-width: 1024px) {
    .newDemo {
        background-color: rgb(247, 246, 240);
        width: 100%;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .newDemo .lds-ellipsis {
        place-self: center;
    }
    
    .demoStructure {
        text-align: left;
        width: 50%;
        margin: 20px 10%;
    }
    
    .demoInput {
        font-size: 20px;
        width: 100%;
        margin-bottom: 30px;
        background-color: #fff;
        border-radius: 4px;
        border: none;
    }
    
    .demoStructure .decodableGenerator {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 5px;
    }

    .demoStructure p {
        font-size: 20px;
        margin: 10px 0 5px 0;
    }
    
    #buttonContainer {
        display: flex;
        justify-content: right;
    }
    
    .demoButton {
        font-size: 20px;
        padding: 8px 12px;
        background-color: #F3C03B;
        border: none;
        border-radius: 4px;
        transition: ease-in-out 0.2s;
        margin-bottom: 20px;
    }

    .clearButton {
        background-color: #AA4747;
        padding: 8px 20px;
        border: none;
        border-radius: 4px;
        margin-left: 10px;
        font-size: 20px;
        margin-bottom: 20px;
        transition: ease-in-out 0.2s;
    }

    .clearButton:hover {
        cursor: pointer;
        background-color: rgb(170, 71, 71, 0.8);
    }
    
    .demoButton:hover {
        cursor: pointer;
        background-color: rgb(243, 192, 59, 0.8);
    }
    
        /*Generated Content Section*/

        .demoContent {
            border: 1px solid;
            width: 50%;
            border-radius: 4px;
            text-align: -webkit-center;
            margin: 0 0 25px 0;
            background-color: rgb(243, 192, 59, 0.1);
            color: rgb(243, 192, 59);
            font-weight: bold;
        }
    
        .demoStoryArea {
            font-size: 24px;
            width: 100%;
            position: relative;
        }
    
        .demoStoryArea div {
            display: flex;
            justify-content: space-evenly;
            height: fit-content;
        }
    
        .demoStoryArea button {
            border-radius: 50%;
            padding: 10px;
            background-color: #F3C03B;
            display: block;
            margin: 0 0 10px 0;
        }

        .right-button {
            top: 0;
            right: 0;
        }

        .left-button {
            top: 0;
        }
    
        .demoArrows {
            height: 20px;
        }    
}
