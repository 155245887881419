* {
    font-family: "Quicksand", sans-serif;
    font-style: normal;
}

@media screen and (max-device-width: 450px) {
    .subBannerContainer {
        display: flex;
        justify-content: center;
        background-color: #276FBF;
    }
    
    .subBannerText {
        font-size: 16px!important;
    }
    
    .subBannerText b {
        color: #f9be2c;
        text-shadow:
        1px 1px 0 #000,
        -1px 1px 0 #000,
        -1px -1px 0 #000,
        1px -1px 0 #000;
    }
}

@media screen and (min-device-width: 451px) and (max-device-width: 1023px) {
    .subBannerContainer {
        display: flex;
        justify-content: center;
        background-color: #276FBF;
    }
    
    .subBannerText {
        font-size: 25px!important;
        padding: 0 10%;
    }
    
    .subBannerText b {
        color: #f9be2c;
        text-shadow:
        1px 1px 0 #000,
        -1px 1px 0 #000,
        -1px -1px 0 #000,
        1px -1px 0 #000;
    }
}

@media screen and (min-device-width: 1024px){
    .subBannerContainer {
        display: flex;
        justify-content: center;
        background-color: #276FBF;
    }
    
    .subBannerText {
        font-size: 20px;
    }
    
    .subBannerText b {
        color: #f9be2c;
        text-shadow:
        1px 1px 0 #000,
        -1px 1px 0 #000,
        -1px -1px 0 #000,
        1px -1px 0 #000;
    }
}
