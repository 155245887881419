.legalDoc {
    margin: 0;
    background-color: rgb(247, 246, 240);
    padding: 30px 10%;
}

.legalDoc h1 {
    margin-top: 0;
}

.ppContactList {
    margin-bottom: 0;
}