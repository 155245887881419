/* Desktop */

@media screen and (min-width: 1024px) {
  .bannerNoImgContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    background-color: #F5F5DC;
    position: relative;
    overflow: hidden;
  }
    
  .bannerNoImgContainer .topText { 
    grid-area: 1 / 1 / 2 / 2;
    padding-top: 50px;
    margin: 0px 50px 0 150px; 
  }
  .bannerNoImgContainer .topText p{ 
    font-size: 20px; 
  }
  
  .bannerNoImgContainer .bottomText { 
    grid-area: 2 / 1 / 3 / 2;
    margin: 0px 50px 0 150px;
    font-size: 30px;
    padding-bottom: 50px;
  }

  .bannerNoImgContainer .bottomText b { 
    color: #F3C03B;
    text-shadow:
    1px 1px 0 #000,
    -1px 1px 0 #000,
    -1px -1px 0 #000,
    1px -1px 0 #000;
  }
  
  .bannerNoImgContainer .bottomText .bannerNoImgBtn {
    border: none;
    background-color: #F3C03B;
    color: black;
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 20px;
    transition: ease-in-out .2s;
    text-decoration: none;
  }
  .bannerNoImgContainer .bottomText .bannerNoImgBtn:hover{
    cursor: pointer;
    background-color: rgb(243,	192,	59, 0.8);
  }
  
  .bottomText .bannerNoImgBtn .link {
    text-decoration: none;
    color: black;
  }
  
  .bottomText .bannerNoImgBtn:hover .link{
    border: none;
    background-color: #292F36!important;
    color: white!important;
  }
  
  .bannerNoImgContainer .icons:nth-child(odd) { 
    grid-area: 1 / 2 / 3 / 3;
    color: #276FBF;
   }
  
   .icons .iconBookClosed {
    position: absolute;
    top: -10px;
    left: 860px;
    height: 100px;
    rotate: 45deg;
   }
}

@media screen and (min-width: 451px) and (max-width: 1023px){
  .bannerNoImgContainer{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr), 0.1fr;
    grid-gap: 0px;
    background-color: #F5F5DC;
    position: relative;
    overflow: hidden;
    padding: 0 0 50px 0;
  }

  .bannerNoImgContainer .topText {
    display: none;
    height: 0px;
  }

  .bannerNoImgContainer .bottomText {
    grid-area: 2 / 1 / 3 / 1;
    font-size: 40px;
    height: fit-content;
    padding: 0px 10%;
    margin-bottom: 100px;
  }

  .bannerNoImgContainer .bottomText b {
    color: #f9be2c;
    text-shadow:
    1px 1px 0 #000,
    -1px 1px 0 #000,
    -1px -1px 0 #000,
    1px -1px 0 #000;
  }

  .bannerNoImgContainer .icons {
    grid-area: 1 / 1 / 1 / 1;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    flex-grow: 1;
    padding: 20% 0;
  }

  .bannerNoImgContainer .mobileHide {
    display: none;
  }

  .bannerNoImgContainer .iconBookClosed:nth-child(even) {
    height: 100px;
    color: #276FBF;
  }
  .bannerNoImgContainer .iconBookClosed:nth-child(odd) {
    height: 100px;
  }

  .bannerNoImgContainer .bannerNoImgBtn {
    background-color: #f9be2c;
    border: none;
    border-radius: 6px;
    padding: 8px 24px;
    color: black;
    text-decoration: none;
  }

  .bannerNoImgContainer .bannerNoImgBtn:active {
    background-color: #276FBF;
    color: white;
    border: none;
    border-radius: 6px;
    padding: 8px 24px;
    font-size: 35px;
  }
}

@media screen and (max-width: 450px) {

  .bannerNoImgContainer{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr), 0.1fr;
    grid-gap: 0px;
    background-color: #F5F5DC;
    position: relative;
    overflow: hidden;
    padding: 0 0 50px 0;
  }

  .bannerNoImgContainer .topText {
    display: none;
    height: 0px;
  }

  .bannerNoImgContainer .bottomText {
    grid-area: 2 / 1 / 3 / 1;
    font-size: 24px;
    height: fit-content;
    padding: 0px 5%;
    margin-bottom: 50px;
  }

  .bannerNoImgContainer .bottomText b {
    color: #f9be2c;
    text-shadow:
    1px 1px 0 #000,
    -1px 1px 0 #000,
    -1px -1px 0 #000,
    1px -1px 0 #000;
  }

  .bannerNoImgContainer .icons {
    grid-area: 1 / 1 / 1 / 1;
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    padding: 20% 0;
  }

  .bannerNoImgContainer .icons .iconBookClosed {
    height: 35px;
    flex: 0 0 10%;
    color: #276FBF;
  }

  .bannerNoImgContainer{
    padding: 0 0 100px 0;
  }

  .bannerNoImgContainer .bannerNoImgBtn {
    background-color: #f9be2c;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 20px;
    text-decoration: none;
    color: black;
    box-shadow: none;
  }

  .bannerNoImgContainer .bottomText b {
    text-shadow: none;
  }
}