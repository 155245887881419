  .studentProgressChart {
    margin: 2px 0 25px 0;
    display: flex;
    flex-direction: column;
    border: 2px solid black;
    border-radius: 8px;
    background-color: #fff;
    width: 90%;
    align-items: flex-start;
    overflow-x: scroll;
    position: relative;
    min-height: 404.82px;
     /* Currently disabled breaking the sidebar, must find alternative to table */
  }

  .studentProgressChart .tileHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: auto;
    position: sticky;
    top: 0;
    left: 0;
  }

  .tableHeadings {
    display: flex;
  }

  .chunkButtons {
    margin-right: 10px;
    display: flex;
    align-items: center;
  }

  .chunkButtons p {
    margin: 0 5px;
  }

  .chunkButtons button {
    border-radius: 50%;
    margin: 0 5px;
    border: none;
    padding: 5px;
    height: 25px;
    width: 25px;
    background-color: RGB(112,112,112, 0.1);
    transition: 0.2s ease-in-out;
  }

  .chunkButtons button:hover {
    cursor: pointer;
    background-color: RGB(112,112,112, 0.3);
  }

  .chevronArrows {
    color: black;
    height: 15px;
  }

  .studentRow {
    margin: 10px 0;
  }

  .tableHeadings p:nth-child(1) {
    margin-left: 180px;
  }

  .tableHeadings p {
    margin: 0px;
    padding: 0px;
    width: 40px;
    text-align: center;
  }

  .chartArea {
    padding: 0 10px;
  }
  
  .studentNameButton {
    position: sticky;
    z-index: 2; /* Ensure it appears above other cells */
  }

.studentProgressChart h2 {
  padding: 10px 0 0 10px;
}

.objectiveRow {
  align-content: center;
}

.objectiveTile {
  width: 40px;
  align-content: center;
  height: 20px;
  border-right: 3px solid white;
}

.studentNameButton {
  position: sticky;
  left: 10px;
  background-color: white;
  margin-right: 10px;
  border: 1px solid;
  border-radius: 4px;
  transition: 0.2s ease-in-out;
}

.studentNameButton:hover {
  background-color: rgb(243, 243, 243);
}


.studentNameButton button {
    width: 150px;
    height: 40px;
    margin: 0 5px;
    border: 1px solid black;
    background-color: transparent;
    color: black;
    border: none;
    position: sticky;
    left: 0;
}

.studentNameButton button:hover {
  cursor: pointer;
}

  