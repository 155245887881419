/********************
*** Mobile Styles ***
********************/

@media screen and (max-width: 450px) {
    .navMobile {
        box-shadow: 0 1px 4px rgb(146 161 176 / 15%);
        height: fit-content;
        background-color: #F3C03B;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px 0 0;
      }

    .navMobile .logo {
        height: 80px;
    }

    .hamburgerLines {
      height: 35px;
      color: #276FBF;
    }

    .hamburgerX {
      height: 35px;
      color: #276FBF;
    }

    .menu-items-mobile {
      z-index: 10;
      position: absolute;
      background-color: #276FBF;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .menu-items-mobile .siteLinks {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 20%;
    }

    .menu-items-mobile .link {
      text-decoration: none;
      color: #FFF;
      font-style: normal;
      margin: 30px 0;
      font-size: 30px;
      font-weight: bold;
    }

    .navSocials {
      color: #fff;
      display: flex;
      margin-bottom: 15%;
      font-size: 16px;
      justify-content: space-around;
    }

    .navSocialLink {
      text-decoration: none;
      color: white;
    }
      
    .menu-items-desktop{
        display: none;
    }

    /*** Footer Styles ***/
    .footerContainer {
      padding: 20px 10%;
      background-color: #F3C03B;
    }

    .footerHeader {
      font-weight: bold;
      font-size: 20px;
      color: black;
      }
      
      .footerText {
      font-size: 16px;
      color: black;
      }
      
      .pageLinks {
      padding: 10px 0 0 0;
      }
      
      .footerList {
      padding-left: 0!important;
      }
      
      .footerLink {
      color: #000;
      text-decoration: none;
      }
      
      footer p {
      color: #000;
      padding: 20px 0 0 0;
      margin: 0;
      }
      
      footer li {
      list-style: none;
      margin: 0 0 10px 0;
      padding: 10px;
      font-size: 20px;
      background-color: transparent;
      border: solid black 1px;
      display: flex;
      justify-content: space-between;
      border-radius: 12px;
      }
      
      footer li:active {
      background-color: transparent;
      }
      
      .arrowIcon {
      color: #000;
      float: right;
      padding: 0px;
      align-self: center;
      }
      
      .footerBrands{
      height: 35px;
      color: #000;
      margin: 10px 25px 0 0;
      }
      
      .Copyright {
      font-size: 16px;
      color: black;
      }
}



/********************
*** Tablet Styles ***
********************/

@media screen and (min-width: 451px) and (max-width: 1023px) {
    /* Navbar Styles */
    .navMobile {
      display: none;
    }
  
    .navbar {
      height: 120px;
      background-color: #F3C03B;
    } 
      
      
      .menu-items-desktop {
        display: flex;
        list-style: none;
        flex-direction: row;
        justify-content: space-between;
      }
      .menu-items-desktop .menulogo {
        height: 120px;
      }
      
      .menu-items-desktop ul {
        display: flex;
        list-style: none;
        align-items: center;
      }
      
      .menu-items-desktop .navBtn {
        border: 1px solid #276FBF;
        color: #fff!important;
        background-color: #276FBF;
        padding: 8px 15px;
        border-radius: 5px;
      }

      .navBtn .arrowIconNav {
        color: #fff;
        padding: 0 0 0 8px;
      }
      
      .menu-items-desktop li {
        margin: 0px 30px 0 30px;
      }
      
      .menu-items-desktop li .link-desktop {
        text-decoration: none;
        color: #000;
        font-size: 20px;
      }  
    /* Footer */

    footer {
      padding: 50px 0;
      background-color: #F3C03B;
      display: flex;
      justify-content: center;
    }
    
    .footerAbout {
      display: none;
    }
    
    .arrowIcon {
      display: none;
    }
    
    .footerHeader {
      display: none;
    }
    
    .footerList {
      display: flex;
      padding: 0;
      justify-content: center;
    }
    
    .footerList .footerLink {
      list-style: none;
      text-decoration: none;
      color: #000;
      font-size: 20px;
      font-weight: bold;
      margin: 0 20px;
      padding: 0;
      border: none;
    }
    
    .footerList .footerLink:hover {
      text-decoration: underline;
      transition: ease-in-out 0.3s;
    }
    
    .socials {
      display: flex;
      justify-content: center;
    }
    
    .footerBrands {
      height: 30px;
      color: #000;
      margin: 30px 10px 0 10px;
    }
    
    .Copyright {
      display: flex;
      justify-content: center;
      font-weight: bold;
      text-align: center;
    }
    
    footer p {
      margin: 10px 0;
      padding: 10px 0 0 0;
      color: #000;
    }
}

/*********************
*** Desktop Styles ***
*********************/

@media screen and (min-width: 1024px) {

  .navMobile{
    display: none;
  }

  .menu-items-mobile {
    display: none;
  }

    /* Navbar Styles */
    .navbar {
      height: 80px;
      background-color: #F3C03B;
    } 
    
    .nav-container .hamburger-lines {
      display: none;
    }
    
    .nav-container {
      display: none;
    }
    
    .menu-items-desktop {
      display: flex;
      list-style: none;
      flex-direction: row;
      justify-content: space-between;
    }
    .menu-items-desktop .menulogo {
      height: 80px;
    }
    
    .menu-items-desktop ul {
      display: flex;
      list-style: none;
      align-items: center;
    }
    
    .menu-items-desktop .navBtn {
      border: 1px solid #276FBF;
      color: #fff!important;
      background-color: #276FBF;
      padding: 8px 15px;
      border-radius: 5px;
    }
    
    .menu-items-desktop .navBtn:hover {
      transition: ease-in-out .2s;
      background-color: #276FBF;
    }
    
    .menu-items-desktop .navBtn:hover {
      background-color: #fff;
      color: #276FBF!important;
      padding: 8px 15px;
      border-radius: 5px;
      text-decoration: none!important;
      text-shadow: none!important;
    }
    
    .menu-items-desktop .navBtn:hover .arrowIconNav {
      color: #276FBF;
      padding: 0 0 0 8px;
    }
    
    .navBtn .arrowIconNav {
      color: #fff;
      padding: 0 0 0 8px;
    }
    
    .menu-items-desktop li {
      margin: 0px 30px 0 30px;
    }
    
    .menu-items-desktop li .link-desktop {
      text-decoration: none;
      color: #000;
      font-size: 20px;
    }  
    
    .menu-items-desktop li .link-desktop:hover {
      color: #FFF;
      text-shadow: -1px 1px 0 #000,
                    1px 1px 0 #000,
                    1px -1px 0 #000,
                   -1px -1px 0 #000;
    }

    /* Footer */

    footer {
      padding: 50px 0;
      background-color: #F3C03B;
      display: flex;
      justify-content: center;
    }
    
    .footerAbout {
      display: none;
    }
    
    .arrowIcon {
      display: none;
    }
    
    .footerHeader {
      display: none;
    }
    
    .footerList {
      display: flex;
      padding: 0;
      justify-content: center;
    }
    
    .footerList .footerLink {
      list-style: none;
      text-decoration: none;
      color: #000;
      font-size: 20px;
      font-weight: bold;
      margin: 0 20px;
      padding: 0;
      border: none;
      transition: ease-in-out 0.2s;
      border-bottom: 1px solid transparent;
      padding-bottom: 3px;
    }
    
    .footerList .footerLink:hover {
      border-bottom: 1px solid black;
    }
    
    .socials {
      display: flex;
      justify-content: center;
    }
    
    .footerBrands {
      height: 25px;
      color: #000;
      margin: 10px 10px 0 10px;
    }
    
    .Copyright {
      display: flex;
      justify-content: center;
      font-weight: bold;
      flex-direction: column;
      align-items: center;
      font-size: 12px;
    }

    .Copyright div {
      display: flex;
      align-items: center;
      font-size: 12px;
    }

    .privacyPolicy {
      margin-right: 5px;
    }

    .tos {
      margin-left: 5px;
    }
    
    footer p {
      margin: 10px 0;
      padding: 10px 0 0 0;
      color: #000;
    }
}