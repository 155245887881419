@media screen and (min-width: 1024px){
    .blogPage {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 75dvh;
    }

    .blogPage h1 {
        font-size: 40px;
    }

    .blogSection {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .postsContainer {
        display: flex;
        flex-wrap: wrap;
        flex: 1 0 25%;
        width: 80%;
        justify-content: center;
    }

    .post {
        width: 30%;
        margin: 10px 1%;
        border-radius: 4px;
        transition: 0.2s ease-in-out;
    }

    .post:hover {
        cursor: pointer;
        transform: translateY(-5px);
        box-shadow: 0 2px 2px 0px;
    }

    .postDetails {
        padding: 3px 0 0 0;
        transition: 0.2s ease-in-out;
    }

    .postDetails .tagsContainer {
        display: flex;
        font-size: 12px;
        padding: 0 10px;
    }

    .postDetails .tagsContainer p {
        margin: 4px 5px 4px 0;
        background-color: #a9a9a9;
        color: white;
        border-radius: 16px;
        padding: 1px 5px;
        text-align: center;
        align-items: center;
    }

    .post .postImg {
        width: 100%;
        max-height: 200px;
        min-height: 200px;
        background-position: top;
        background-color:blanchedalmond;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 4px 4px 0 0;
    }

    .post .postTitle {
        margin: 0;
        padding: 0 10px;
    }

    .post .postBody {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        padding: 0 10px;
        margin: 5px 0;
    }

    .post .postAuthor {
        padding: 0 10px;
        margin: 5px 0;
        font-size: 14px;
        color: #a9a9a9;
    }

    .pagination {
        display: flex;
        padding: 0;
    }

    .pagination .active {
        background-color: #276FBF;
        color: white;
        font-weight: bold;
    }

    .pagination .active a {
        color: white;
    }

    .pagination li {
        list-style: none;
        transition: 0.2s ease-in-out;
        margin: 0 3px;
        height: 25px;
        width: 25px;
        border-radius: 50%;
        text-align: center;
        align-content: center;
    }

    .pagination li:hover {
        background-color: #A8C5E5;
    }

    .pagination li a {
        text-decoration: none;
        color: black;
    }

    /* Full Blog Styles */

    .fullBlog {
        position: absolute;
        z-index: 2;
        background-color: white;
        width: 80%;
        border: 1px solid black;
        min-height: 70dvh;
        overflow-y: scroll;
    }

    .fullBlog .heroSection {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-position:left;
        min-height: 200px;
        position: relative;
    }

    .heroSection p {
        position: absolute;
        top: 0;
        right: 0;
        margin: 20px 20px 0 0;
        font-weight: bold;
    }

    .titleWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .titleWrapper h2 {
        font-size: 30px;
    }

    .fullBlog .tagsContainer {
        display: flex;
        font-size: 12px;
        padding: 0 10px;
    }

    .fullBlog .tagsContainer p {
        margin: 4px 5px 4px 0;
        background-color: #a9a9a9;
        color: white;
        border-radius: 16px;
        padding: 1px 5px;
        text-align: center;
        align-items: center;
    }

    .bodyContent {
        width: 70%;
        margin: auto;
    }
}

@media screen and (min-width: 451px) and (max-width: 1023px) {
    .blogPage {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 75dvh;
    }

    .blogPage h1 {
        font-size: 40px;
    }

    .blogSection {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .postsContainer {
        display: flex;
        flex-wrap: wrap;
        width: 80%;
        justify-content: center;
    }

    .post {
        width: 30%;
        margin: 10px 1%;
        border-radius: 4px;
        flex: 0 0 48%;
        transition: 0.2s ease-in-out;
    }

    .post:hover {
        cursor: pointer;
        transform: translateY(-5px);
        box-shadow: 0 2px 2px 0px;
    }

    .postDetails {
        padding: 3px 0 0 0;
        transition: 0.2s ease-in-out;
    }

    .postDetails .tagsContainer {
        display: flex;
        font-size: 12px;
        padding: 0 10px;
    }

    .postDetails .tagsContainer p {
        margin: 4px 5px 4px 0;
        background-color: #a9a9a9;
        color: white;
        border-radius: 16px;
        padding: 1px 5px;
        text-align: center;
        align-items: center;
    }

    .post .postImg {
        width: 100%;
        max-height: 200px;
        min-height: 200px;
        background-position: top;
        background-color:blanchedalmond;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 4px 4px 0 0;
    }

    .post .postTitle {
        margin: 0;
        padding: 0 10px;
    }

    .post .postBody {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        padding: 0 10px;
        margin: 5px 0;
    }

    .post .postAuthor {
        padding: 0 10px;
        margin: 5px 0;
        font-size: 14px;
        color: #a9a9a9;
    }
}

@media screen and (max-width: 450px) {

    .blogPage {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .blogPage h1 {
        font-size: 24px;
    }

    .blogSection {
        display: block;
    }

    .postsContainer {
        width: 80%;
        margin: auto;
    }

    .post {
        margin: 10px 1%;
        border-radius: 4px;
        transition: 0.2s ease-in-out;
    }

    .postDetails {
        padding: 3px 0 0 0;
        transition: 0.2s ease-in-out;
        border: 1px solid black;
        border-radius: 0 0 4px 4px;
        border-top: none;
    }

    .postDetails .tagsContainer {
        display: flex;
        font-size: 12px;
        padding: 0 10px;
    }

    .postDetails .tagsContainer p {
        margin: 4px 5px 4px 0;
        background-color: #a9a9a9;
        color: white;
        border-radius: 16px;
        padding: 1px 5px;
        text-align: center;
        align-items: center;
    }

    .post .postImg {
        width: 100%;
        max-height: 200px;
        min-height: 200px;
        background-position: top;
        background-color:blanchedalmond;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 4px 4px 0 0;
    }

    .post .postTitle {
        margin: 0;
        padding: 0 10px;
        font-size: 20px;
    }

    .post .postBody {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        padding: 0 10px;
        margin: 5px 0;
        font-size: 14px;
    }

    .post .postAuthor {
        padding: 0 10px;
        margin: 5px 0;
        font-size: 14px;
        color: #a9a9a9;
    }

    /* Pagination */

    .pagination {
        display: flex;
        padding: 0;
        justify-content: center;
    }

    .pagination .active {
        background-color: #276FBF;
        color: white;
        font-weight: bold;
    }

    .pagination .active a {
        color: white;
    }

    .pagination li {
        list-style: none;
        transition: 0.2s ease-in-out;
        margin: 0 3px;
        height: 25px;
        width: 25px;
        border-radius: 50%;
        text-align: center;
        align-content: center;
    }

    .pagination li:hover {
        background-color: #A8C5E5;
    }

    .pagination li a {
        text-decoration: none;
        color: black;
    }

    /* Full Blog */

    .fullBlog {
        position: absolute;
        top: 0;
        z-index: 2;
        background-color: white;
        width: 100%;
        border: 1px solid black;
        min-height: 100%;
        overflow-y: scroll;
    }

    .fullBlog .heroSection {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-position:left;
        min-height: 200px;
        position: relative;
    }

    .heroSection p {
        position: absolute;
        top: 0;
        right: 0;
        margin: 20px 20px 0 0;
        font-weight: bold;
    }

    .heroSection button {
        background-color: transparent;
        border: none;
        position: sticky;
        left: 100%;
        top: 10;
        padding: 10px 20px;
    }

    .heroSection .closeBlogX {
        height: 30px;
    }

    .titleWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .titleWrapper h2 {
        font-size: 30px;
    }

    .fullBlog .tagsContainer {
        display: flex;
        font-size: 12px;
        padding: 0 10px;
    }

    .fullBlog .tagsContainer p {
        margin: 4px 5px 4px 0;
        background-color: #a9a9a9;
        color: white;
        border-radius: 16px;
        padding: 1px 5px;
        text-align: center;
        align-items: center;
    }

    .bodyContent {
        width: 80%;
        margin: auto;
    }
}