@media screen and (max-width: 450px) {

    .signInPage {
        background-color: beige;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 80vh;
        text-align: -webkit-center;
    }

    .teacherSignInContainer {
        display: flex;
        flex-direction: column;
        background-color: white;
        border-radius: 30px;
        height: -moz-fit-content;
        height: fit-content;
        padding: 50px 0px;
        border: solid 1px #292F36;
      }

    .signInRight {
        width: 80%;
    }
    
      .teacherSignInHeader {
        color: #292F36;
        font-size: 24px;
        font-weight: bold;
        padding-bottom: 0;
        margin-bottom: 50px;
      }
    
      .teacherSignInContainer .teacherSignInInput {
        height: 20px;
        margin: 15px;
        width: 70%;
        place-self: center;
        border: none;
        border-bottom: 1px solid #000;
        border-radius: 0px;
        font-size: 16px;
      }
    
      .teacherSignInButton {
        border: inset 1px #276FBF;
        background-color: #276FBF;
        color: white;
        font-size: 20px;
        padding: 8px 12px;
        width: 50%;
        border-radius: 8px;
        margin: 30px 0 20px 0;
        align-self: center;
      }

    
    .teacherSignInContainer p {
        font-size: 12px;
        margin: 0;
    }  
}

@media screen and (min-width: 451px) and (max-width: 767px) {
    .teacherSignInContainer {
        display: flex;
        flex-direction: column;
        background-color: white;
        border-radius: 30px;
        height: -moz-fit-content;
        height: fit-content;
        padding: 100px 50px;
        border: solid 1px #292F36;
      }

    .signInRight {
        width: 80%;
    }
    
      .teacherSignInHeader {
        color: #292F36;
        font-size: 30px;
        font-weight: bold;
        padding-bottom: 0;
        margin-bottom: 50px;
      }
    
      .teacherSignInContainer .teacherSignInInput {
        height: 30px;
        margin: 10px;
        width: 70%;
        place-self: center;
        border: none;
        border-bottom: 1px solid #000;
        border-radius: 0px;
        font-size: large;
      }
    
      .teacherSignInButton {
        border: inset 1px #276FBF;
        background-color: #276FBF;
        color: white;
        font-size: 20px;
        padding: 8px 12px;
        width: 50%;
        border-radius: 8px;
        margin: 30px 0 20px 0;
        align-self: center;
      }
    
      .teacherSignInButton:hover {
        border: inset 1px #276FBF;
        background-color: transparent;
        color: #000;
        cursor: pointer;
    }
    
    .teacherSignInContainer p {
        margin: 0;
    }  
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .teacherSignInContainer {
        display: flex;
        flex-direction: column;
        background-color: white;
        border-radius: 30px;
        height: -moz-fit-content;
        height: fit-content;
        padding: 100px 50px;
        border: solid 1px #292F36;
      }

    .signInRight {
        width: 80%;
    }
    
      .teacherSignInHeader {
        color: #292F36;
        font-size: 30px;
        font-weight: bold;
        padding-bottom: 0;
        margin-bottom: 50px;
      }
    
      .teacherSignInContainer .teacherSignInInput {
        height: 30px;
        margin: 10px;
        width: 70%;
        place-self: center;
        border: none;
        border-bottom: 1px solid #000;
        border-radius: 0px;
        font-size: large;
      }
    
      .teacherSignInButton {
        border: inset 1px #276FBF;
        background-color: #276FBF;
        color: white;
        font-size: 20px;
        padding: 8px 12px;
        width: 50%;
        border-radius: 8px;
        margin: 30px 0 20px 0;
        align-self: center;
      }
    
      .teacherSignInButton:hover {
        border: inset 1px #276FBF;
        background-color: transparent;
        color: #000;
        cursor: pointer;
    }
    
    .teacherSignInContainer p {
        margin: 0;
    }    
}

@media screen and (min-device-width: 1024px ) {
    .signInPage {
        background-color: beige;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 90vh;
        text-align: -webkit-center;
    }
    
    .signInRight {
        width: 80%;
    }
    
    .teacherSignInContainer {
        display: flex;
        flex-direction: column;
        background-color: white;
        border-radius: 30px;
        height: -moz-fit-content;
        height: fit-content;
        padding: 100px 50px;
        border: solid 1px #292F36;
        width: 50%;
      }
    
      .teacherSignInHeader {
        color: #292F36;
        font-size: 30px;
        font-weight: bold;
        padding-bottom: 0;
        margin-bottom: 50px;
      }
    
      .teacherSignInContainer .teacherSignInInput {
        height: 30px;
        margin: 10px;
        width: 70%;
        place-self: center;
        border: none;
        border-bottom: 1px solid #000;
        border-radius: 0px;
        font-size: large;
      }
    
      .teacherSignInButton {
        border: inset 1px #276FBF;
        background-color: #276FBF;
        color: white;
        font-size: 20px;
        padding: 8px 12px;
        width: 50%;
        border-radius: 8px;
        margin: 30px 0 20px 0;
        align-self: center;
      }
    
      .teacherSignInButton:hover {
        border: inset 1px #276FBF;
        background-color: transparent;
        color: #000;
        cursor: pointer;
    }
    
    .teacherSignInContainer p {
        margin: 0;
    }    
}