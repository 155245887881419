* {
    font-family: "Quicksand", sans-serif;
    font-style: normal;
}

@media screen and (max-width: 450px) {
    .whatsNextContainer {
        text-align: -webkit-center;
        padding: 50px 0;
        background-color: rgb(247, 246, 240);
    }

    .whatsNextContainer h2{
        font-size: 20px;
    }

    .updatesContainer {
        display: flex;
        align-items: center;
        width: 80%;
        flex-direction: column;
    }

    .updates {
        width: 80%;
        border: 1px solid black;
        border-radius: 8px;
        padding: 20px;
        margin: 10px 10px;
        box-shadow: 0px 4px 4px;
        position: relative;
        background-color: white;
    }

    .updates .updatesIcon {
        height: 60px;
        margin: 10px 0 0 0;
    }

    .updates h3 {
        font-size: 20px;
    }

    .updates p {
        font-size: 16px;
    }

    .updates .community {
        position: relative;
        z-index: 2;
        color: #F3C03B;
    }

    .updates .community2 {
        position: absolute;
        top: 6%;
        left: 20%;
        rotate: -10deg;
        color: #276FBF;
    }

    .updates .community3 {
        position: absolute;
        top: 6%;
        right: 20%;
        rotate: 10deg;
        color: #AA4747;
    }

    .updates .audio {
        color: #276FBF  
    }

    .updates .dashboard {
        color: #AA4747;
    }
}

@media screen and (min-device-width: 451px) and (max-device-width: 1023px) {
    .whatsNextContainer {
        display: flex;
        flex-direction: column;
    }

    .whatsNextContainer h2{
        font-weight: bolder;
        font-size: 40px;
        margin-bottom: 10px;
    }

    .updatesContainer {
        display: flex;
        align-items: center;
        align-self: center;
        justify-content: center;
        width: 80%;
        flex-direction: column;
    }

    .updates {
        width: 50%;
        border: 1px solid black;
        border-radius: 8px;
        padding: 24px;
        margin: 10px 10px;
        box-shadow: 0px 4px 4px;
        position: relative;
    }

    .updates .updatesIcon {
        height: 60px;
        margin: 10px 0 0 0;
    }

    .updates h3 {
        font-size: 30px;
    }

    .updates p {
        font-size: 25px;
    }

    .updates .community {
        position: relative;
        z-index: 2;
        color: #F3C03B;
    }

    .updates .community2 {
        position: absolute;
        top: 6%;
        left: 20%;
        rotate: -10deg;
        color: #276FBF;
    }

    .updates .community3 {
        position: absolute;
        top: 6%;
        right: 20%;
        rotate: 10deg;
        color: #AA4747;
    }

    .updates .audio {
        color: #276FBF  
    }

    .updates .dashboard {
        color: #AA4747;
    }
}


@media screen and (min-device-width: 1024px){
    .whatsNextContainer {
        display: flex;
        flex-direction: column;
        padding: 25px 0;
        background-color: rgb(247, 246, 240);
    }

    .whatsNextContainer h2{
        font-weight: bolder;
        font-size: 30px;
        margin-bottom: 10px;
    }

    .updatesContainer {
        display: flex;
        align-self: center;
        justify-content: center;
        width: 80%;
    }

    .updates {
        width: 30%;
        border: 1px solid black;
        border-radius: 8px;
        padding: 24px;
        margin: 10px 10px;
        box-shadow: 0px 4px 4px;
        position: relative;
        background-color: #fff;
    }

    .updates .updatesIcon {
        height: 50px;
        margin: 10px 0 0 0;
    }

    .updates h3 {
        font-size: 30px;
    }

    .updates p {
        font-size: 20px;
    }

    .updates .community {
        position: relative;
        z-index: 2;
        color: #F3C03B;
    }

    .updates .community2 {
        position: absolute;
        top: 6%;
        left: 20%;
        rotate: -10deg;
        color: #276FBF;
    }

    .updates .community3 {
        position: absolute;
        top: 6%;
        right: 20%;
        rotate: 10deg;
        color: #AA4747;
    }

    .updates .audio {
        color: #276FBF  
    }

    .updates .dashboard {
        color: #AA4747;
    }

    .updates:hover {
        transform: scale(1.1);
        margin: 20px;
        transition: 0.5s;
    }
}