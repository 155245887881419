.libraryList {
    margin: 50px auto;
    position: relative;
}

.libraryList ul{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
  
  .libraryList ul {
    list-style-type: none;
    padding: 0;
  }
  
  .libraryList li {
    cursor: pointer;
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    transition: 0.2s ease-in-out;
    box-shadow: 0px 3px 4px 0.5px #474747;
  }

  .libraryList li:hover {
    transform: scale(1.025);
    background-color: #f1f1f1;
  }

  .topHalfCard {
    height: 50%;
    position: absolute;
  }

  .phonemeContainer {
    display: flex;
    justify-content: center;
  }

  .phonemeContainer p {
    margin: 0 2px;
    font-weight: bolder;
    font-size: 40px;
  }

  .libraryList li .phonemeTitleDivider {
    width: 125px;
  }

  .libraryList li .title {
    position: absolute;
    top: 50%;
    font-size: 14px;
    padding: 0 10px;
  }

.libraryList li {
    margin: 0 10px 20px 0;
    min-height: 200px;
    max-height: 200px;
    max-width: 200px;
    min-width: 200px;
    border-radius: 8px;
    background-color: #eee;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    overflow-y: hidden;
    overflow-x: hidden;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

.modalContent {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    max-width: 500px;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modalContent .closeButton {
    align-self: end;
}

.deleteButton, .exportButton {
    margin: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.closeButton {
    background-color: transparent;
    padding: 0;
    margin: 0 0 20px 0;
    border: none;
    cursor: pointer;
}

.closeButton:hover {
    transform: scale(1.1);
    transition: 0.2s ease-in-out;   
}

.closeButtonIcon {
    height: 20px;
}
  
.deleteButton {
    background-color: #f44336;
    color: #fff;
    border: 1px solid #f44336;
}

.deleteButton:hover{
    border: 1px solid #f44336;
    background-color: transparent;
    color: #f44336;
    transition: 0.2s ease-in-out;
}
  
.exportButton {
    background-color: #4CAF50;
    color: #fff;
    border: 1px solid #4CAF50;
}

.exportButton:hover {
    border: 1px solid #4CAF50;
    background-color: transparent;
    color: #4CAF50;
    transition: 0.2s ease-in-out;
}

.modalContent ul {
    justify-content: center;
}

.modalContent li {
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0 10px;
    box-shadow: none;
    min-height: fit-content;
    min-width: fit-content;
}

.modalContent li::before {
    border: none;
    display: none;
}

.modalContent li::after {
    display: none;
}