.headerInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 20px 0;
}

.editSaveBtn {
    position: absolute;
    right: 20px;
    background-color: transparent;
    border: none;
}

.editSaveBtn .icons {
    height: 20px;
}

.editSaveBtn .icons:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.headerInfo label {
    font-size: 20px;
    margin-right: 20px;
}

.headerInfo input {
    width: 100px;
    text-align: center;
    border: 1px solid black;
    border-radius: 4px;
    font-size: 18px;
}

.headerInfo button {
    padding: 8px 24px;
    font-size: large;
    border-radius: 4px;
    background-color: #276FBF;
    color: white;
    border: 1px solid black;
}

.headerInfo button:hover {
    cursor: pointer;
    border: 1px solid #276FBF;
    background-color: white;
    color: #276FBF;
    transition: ease-in-out 0.2s;
}

.curriculumCreatorContainer {
    display: flex;
    flex-direction: row-reverse;
    height: fit-content;
    margin-bottom: 50px;
}

.boxesContainer {
    flex: 1 1 0%;
    overflow-y: scroll;
    height: 80vh;
    margin-top: 50px;
}

.objectiveBox {
    height: 50px;
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid black;
    border-radius: 8px;
    margin-bottom: 10px;
    background-color: white;
    position: relative;
}

.draggablesContainer {
    margin: 0 50px 0 0;
    width: 50%;
    height: fit-content;
}

.draggablesContainer p {
    font-size: 25px;
    margin: 0 0 20px 0;
}

.draggablesContainer div {
    margin-bottom: 20px;
}

.droppedPhonemes {
    display: flex;
    justify-content: space-around;
}

.blockContainer {
    display: flex;
    flex-wrap: wrap;
    background-color: white;
    border: 1px solid black;
    border-radius: 4px;
    min-height: 100px;
    padding: 10px;
}

.draggablesContainer .consonantblends div {
    align-content: center;
    background-color: #AA4747;
    border: 1px solid black;
    border-radius: 4px;
    margin: 0 0 0 5px;
    padding: 5px;
    color: white;
    width: fit-content;
    margin: 5px;
    max-height: 30px;
}

.draggablesContainer .consonants div {
    align-content: center;
    background-color: #276FBF;
    border: 1px solid black;
    border-radius: 4px;
    margin: 0 0 0 5px;
    padding: 5px;
    color: white;
    width: fit-content;
    margin: 5px;
    max-height: 30px;
}

.draggablesContainer .shortvowels div {
    align-content: center;
    background-color: #F3C03B;
    border: 1px solid black;
    border-radius: 4px;
    margin: 0 0 0 5px;
    padding: 5px;
    color: white;
    width: fit-content;
    margin: 5px;
    max-height: 30px;
}

.draggablesContainer .longvowels div {
    align-content: center;
    background-color: #4CAF50;
    border: 1px solid black;
    border-radius: 4px;
    margin: 0 0 0 5px;
    padding: 5px;
    color: white;
    width: fit-content;
    margin: 5px;
    max-height: 30px;
}

.draggablesContainer .prefixes div {
    align-content: center;
    background-color: #BDADEA;
    border: 1px solid black;
    border-radius: 4px;
    margin: 0 0 0 5px;
    padding: 5px;
    color: white;
    width: fit-content;
    margin: 5px;
    max-height: 30px;
}

.draggablesContainer .suffixes div {
    align-content: center;
    background-color: #BA5624;
    border: 1px solid black;
    border-radius: 4px;
    margin: 0 0 0 5px;
    padding: 5px;
    color: white;
    width: fit-content;
    margin: 5px;
    max-height: 30px;
}



.shortvowels div:hover, .consonantblends div:hover, .consonants div:hover, .suffixes div:hover, .prefixes div:hover, .longvowels div:hover {
    cursor: grab;
}

.shortvowels div:active, .consonantblends div:active, .consonants div:active, .suffixes div:active, .prefixes div:active, .longvowels div:active {
    cursor: grabbing;
}

.droppedPhonemes div {
    align-content: center;
    background-color:darkolivegreen;
    border: 1px solid black;
    border-radius: 4px;
    margin: 0 0 0 5px;
    padding: 5px;
    color: white;
    width: fit-content;
    margin: 5px 5px 5px 20px;
    max-height: 30px;
}