.pageBreakSection {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: rgb(247, 246, 240);
}

.pageBreakSection hr {
    height: 1px;
    width: 500px;
}

.pageBreakSection img {
    height: 100px;
}