.pageNotFoundSection {
    display: flex;
    flex-direction: row-reverse;
    height: 80dvh;
    justify-content: center;
    align-items: center;
}

.pageNotFoundSection img {
    height: 60%;
}

.pageNotFoundSection div {
    text-align: center;
}

.pageNotFoundSection div h1 {
    font-size: 24px;
    margin: 0;
}

.pageNotFoundSection div p {
    font-size: 18px;
    margin: 10px auto 20px auto;
}