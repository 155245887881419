.quickDataContainer {
    display: flex;
    justify-content: space-between;
    width: 90%;
}

.quickDataContainer div {
    flex: 1 20%;
    text-align: left;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: 2px solid black;
    border-radius: 4px;
    justify-content: space-evenly;
    padding: 5px 10px;
}

.quickDataContainer h4 {
    font-size: 20px;
    margin: 0;
    padding: 0;
}

.quickDataContainer div p {
    margin: 0;
    font-size: 20px;
}

.quickDataContainer .quickDataCard:nth-child(odd) {
    margin-right: 5px;
}

.quickDataContainer .quickDataCard:nth-child(even) {
    margin-left: 5px;
}

.quickDataContainer .quickDataCard:nth-child(2) {
    margin-right: 5px;
}

.quickDataContainer .quickDataCard:nth-child(3) {
    margin-left: 5px;
}

