@media screen and (max-width: 768px) {
    .stepsSection {

    }

    .stepsSection h2 {
        font-size: 20px!important;
    }

    .stepsContainer {
        width: 100%;
        flex-direction: column;
    }

    .stepDesc {
        width: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 20px 0 0 0;
    }

    .stepDesc img {
        height: auto!important;
        max-width: 95dvw!important;
    }

    .stepsTabs {
        margin: auto;
        width: 90%!important;
        overflow: scroll;
    }

    .stepsTabs span{
        display: flex;
        overflow: scroll;
        width: max-content;
    }

    .stepsTabs .steps {
        border-bottom: 2px solid rgb(167, 167, 167, 0.3);
        border-left: none!important;
        font-size: 16px;
    }

    .stepsTabs .active {
        border-bottom: 2px solid #F3C03B;
    }

    .job_bullets {
        display: block;
        font-size: 16px!important;
    }
}

@media screen and (min-width: 769px) {
    .stepsContainer {
        width: 80%;
    }
}

.stepsSection {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: rgb(247, 246, 240);
    padding: 50px 0;
}

.stepsSection h2 {
    font-size: 30px;
}

.stepsContainer{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 80%;
    margin: 25px 0;
}

.stepsTabs {
    color: white;
    width: 200px;
}

.stepsTabs .steps {
    color: #000;
    padding: 12px 12px;
    border-left: 2px solid rgb(167, 167, 167, 0.3);
    transition: 0.25s linear;
    text-align: left;
}

.stepsTabs .active {
    border-left: 2px solid #F3C03B;
}

.stepsTabs .steps:hover {
    cursor: pointer;
    background-color: rgba(118, 117, 117, 0.1);
}

.stepDesc {
    color: white;
    width: fit-content;
    position:relative;
}

.stepDesc img {
    height: 400px;
    max-width: 750px;
    border-radius: 8px;
}

.bulletContainer {
    position: absolute;
    top: 0;
    width: 99.5%;
    height: 98.5%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 2px solid black;
    transition: 0.2s ease-in-out;
}

.bulletContainer:hover {
    background-color: rgb(0, 0, 0, 0.7);
}

.stepDesc:hover img {
    opacity: 0.5;
}

.stepDesc .job_bullets {
    color: #fff;
    padding-left: 15px;
    list-style:square;
    width: 80%;
    font-size: 24px;
}

.stepDesc .job_bullets li {
    margin-bottom: 10px;
    padding-left: 0px;
    text-align: left;
}

.stepDesc .job_bullets li::marker {
    color: #fff;
}