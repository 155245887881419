.settingsContainer {
    background-color: white;
    border: 1px solid black;
    border-radius: 8px;
    padding: 8px 12px;
    display: flex;
    flex-direction: column;
}

.configureClassContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tooltip {
    position: relative;
    display: inline-block;
    margin: 0 0 0 10px;
}

.tooltip:hover {
    cursor: help;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 200px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    left: 20px;
    top: -12px;
  }

  .tooltip .tooltiptext::after {
    content: " ";
    position: absolute;
    top: 50%;
    right: 100%; /* To the left of the tooltip */
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent black transparent transparent;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
    cursor:help;
  }

.settingDropdown {
    width: 100px;
}

.settingDropdown select {
    width: 100%;
    font-size: 15px;
    padding: 5px;
    border-radius: 8px;
}

.settingDropdown select:hover {
    cursor: pointer;
}

.confirmClassBtn {
    padding: 8px 24px;
    font-size: large;
    border-radius: 4px;
    background-color: #6DA34D;
    color: white;
    border: 1px solid black;
    align-self: center;
    margin-top: 20px;
}

.confirmClassBtn:hover {
    cursor: pointer;
    border: 1px solid #6DA34D;
    background-color: white;
    color: #6DA34D;
    transition: ease-in-out 0.2s;
}
